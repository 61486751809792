import React, { useEffect, useState } from "react";
import "../../ui/components/estratificacao-defeitos/estratificacoes.css";
import { BsSearch } from "react-icons/bs";
import BarChat from "../../ui/components/grafico";
import { GoGraph } from "react-icons/go";
import { AiFillInfoCircle } from "react-icons/ai";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import {
  GetMensalByForn,
  GetPQMFEstratificacaoOrigem,
  GetPQMFEstratificacaoPrincipaisDefeitos,
  GetPQMFEstratificacaoPrincipaisDefeitosOrigens,
  GetPQMFEstratificacaoSeveridade,
  GetSuppliers,
  GetSuppliersByUnity,
} from "../../data/services";
import { GetYearsArry } from "../../helpers/helpers";
import { useAuth } from "../../context/auth";
import { GetSuppliersById } from "../../data/services";
import secureLocalStorage from "react-secure-storage";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const ErrorsNce = () => {
  const token: any = secureLocalStorage.getItem("access_token");

  const Data: any = [
    // {
    //   id: 1,
    //   year: 2016,
    //   userGain: 5000,
    //   userLost: 823,
    //   title: "teste1",
    // },
    // {
    //   id: 2,
    //   year: 2017,
    //   userGain: 5000,
    //   userLost: 345,
    //   title: "teste2",
    // },
    // {
    //   id: 3,
    //   year: 2018,
    //   userGain: 5000,
    //   userLost: 555,
    //   title: "teste3",
    // },
    // {
    //   id: 4,
    //   year: 2019,
    //   userGain: 5000,
    //   userLost: 4555,
    //   title: "teste4",
    // },
    // {
    //   id: 5,
    //   year: 2020,
    //   userGain: 4300,
    //   userLost: 234,
    //   title: "teste5",
    // },
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        // display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: 
        {
          type: 'linear',
          position: "right",
          id: "y-axis-2",
          ticks: {
              min: 0                        
          },
        },
      }
    }

  const [forn, setForn] = React.useState([]);
  const [fornItem, setFornItem] = React.useState<any>("Selecione");
  const [units, setUnits] = React.useState([]);
  const [principaisDefeitos, setPrincipaisDefeitos] = React.useState([]);
  const [originDefect, setOriginDefect] = React.useState("Recebimento");
  const [defectsOrigin, setDefectsOrigin] = React.useState([]);
  const [dataOriginDefects, setDataOriginDefects] = React.useState([]);
  const [defeitosSeveridade, setDefeitosSeveridade] = React.useState([]);
  const [dataSeveridadeDefeitos, setDataSeveridadeDefeitos] = React.useState(
    []
  );
  // const [severidade, setSeveridade] = useState<any>([]);
  const [severidade, setSeveridade] = React.useState("Crítico");
  const [principaisDefeitosOrigens, setPrincipaisDefeitosOrigens] = React.useState([]);
  const [year, setYear] = useState<any>([]);
  // const [year, setYear] = React.useState("2022");
  const [objectForn, setObjectForn] = React.useState<any>({
    idUnidade: 0,
    idForn: 0
  });

  const [disabledButton, setDisabledButton] = React.useState(true)
  const [anosPesquisa, setAnosPesquisa] = React.useState<number[]>([])

  const [showGrafic, setShowGrafic] = React.useState<boolean>(false);
  const [userData, setUserData] = React.useState({
    labels: Data.map((item: any) => item.title),
  });
  const [defeitosOrigem, setDefeitosOrigem] = React.useState([]);
  const { user: { profile, idFabrica } } = useAuth()

  React.useEffect(() => {
    profile === "usuarioFornecedor"
      ? GetSuppliersById(setForn, idFabrica)
      : GetSuppliers(setForn);
    GetSuppliers(setForn);
    GetSuppliersByUnity(setUnits);
    setAnosPesquisa(GetYearsArry(new Date().getFullYear(), 7));
  }, []);

  const FilterItemByName =
    fornItem &&
    units.filter((item: any) => {
      return item.idFornecedor == fornItem;
    });



  const Search = React.useCallback(async () => {
    console.log(year, objectForn?.idForn, objectForn?.idUnidade)
    GetPQMFEstratificacaoPrincipaisDefeitos(
      setPrincipaisDefeitos,
      year ?? 2023,
      objectForn?.idForn,
      objectForn?.idUnidade
    );

    GetPQMFEstratificacaoPrincipaisDefeitosOrigens(
      setPrincipaisDefeitosOrigens,
      year ?? 2023,
      objectForn?.idForn,
      objectForn?.idUnidade
    );

    GetPQMFEstratificacaoSeveridade(
      setDefeitosSeveridade,
      severidade,
      year,
      objectForn?.idForn,
      objectForn?.idUnidade
    );

    GetPQMFEstratificacaoOrigem(
      setDefectsOrigin,
      originDefect,
      year,
      objectForn?.idForn,
      objectForn?.idUnidade
    );

    setShowGrafic(true);
  }, [
    objectForn?.idForn,
    objectForn?.idUnidade,
    originDefect,
    severidade,
    year,
  ]);

  const filterSeveridade = (sev: any) => {
    GetPQMFEstratificacaoSeveridade(
      setDefeitosSeveridade,
      sev,
      year,
      objectForn?.idForn,
      objectForn?.idUnidade
    );
  };

  const filterOrigem = (or: any) => {
    GetPQMFEstratificacaoOrigem(
      setDefectsOrigin,
      or,
      year,
      objectForn?.idForn,
      objectForn?.idUnidade
    );
  };

  useEffect(() => {
    const QTDEFEITOS = principaisDefeitos.map((item: any) => {
      return parseInt(item.qtd_defeitos);
    });

    const PERCENTUAL = principaisDefeitos.map((item: any) => {
      return parseInt(item.percentual);
    });

    const PERCENTUALRESULT: number[] = []

    let PERCENTUALSOMA = 0;

    for (let i = 0; i < PERCENTUAL.length; i++) {
      PERCENTUALSOMA += PERCENTUAL[i];
      PERCENTUALRESULT.push(PERCENTUALSOMA);
    }

    const TIPO_DEFEITO = principaisDefeitos.map((item: any) => {
      return item.tipo_defeito;
    });

    setUserData((prev: any) => ({
      ...prev,
      labels: TIPO_DEFEITO,
      datasets: [{
          type: 'bar',
          yAxisID: "y-axis-1",
          label: 'Valor',
          borderColor: 'rgba(163, 142, 23, 0.8)',
          backgroundColor: 'rgba(163, 142, 23, 0.8)',
          fill: true,
          data: QTDEFEITOS
      }, {
          type: 'line',
          label: 'Percentual Acumulado',
          // yAxisID: 'y-axis-2',
          backgroundColor: 'gray',
          borderColor: 'gray',
          borderWidth: 2,
          fill: false,
          data: PERCENTUALRESULT
        }],
      }));
     

    const ORIGEM = principaisDefeitosOrigens?.map((item: any) => item.origem);

    const PERCENTUAL_ORIGEM = principaisDefeitosOrigens?.map((item: any) =>
      parseInt(item.percentual)
    );

    const PERCENTUAL_ORIGEM_RESULT: number[] = []

    let PERCENTUAL_ORIGEM_SOMA = 0;

    for (let i = 0; i < PERCENTUAL_ORIGEM.length; i++) {
      PERCENTUAL_ORIGEM_SOMA += PERCENTUAL_ORIGEM[i];
      PERCENTUAL_ORIGEM_RESULT.push(PERCENTUAL_ORIGEM_SOMA);
    }

    const TOTAL_DEFEITOS = principaisDefeitosOrigens?.map((item: any) =>
      parseInt(item.qtd_defeitos)
    );

    setDefeitosOrigem((prev: any) => ({
      ...prev,
      labels: ORIGEM,
      datasets: [
        {
          type: 'bar',
          yAxisID: "y-axis-1",
          label: 'Valor',
          borderColor: 'rgba(163, 142, 23, 0.8)',
          backgroundColor: 'rgba(163, 142, 23, 0.8)',
          fill: true,
          data: TOTAL_DEFEITOS
        },
        {
          type: 'line',
          label: 'Percentual Acumulado',
          // yAxisID: "y-axis-2",
          backgroundColor: 'gray',
          borderColor: 'gray',
          borderWidth: 2,
          fill: false,
          data: PERCENTUAL_ORIGEM_RESULT
        },
      ],
    }));

    const UNIDADE = defeitosSeveridade?.map(
      (item: any) => item.id_fornecedor_unidade
    );

    const QUANTIDADE_DEFEITOS = defeitosSeveridade?.map((item: any) =>
      parseInt(item.qtd_defeitos)
    );

    const PERCENTUAL_SEVERIDADE = defeitosSeveridade?.map((item: any) =>
      parseInt(item.percentual)
    );

    const PERCENTUAL_SEVERIDADE_RESULT: number[] = []

    let PERCENTUAL_SEVERIDADE_SOMA = 0;

    for (let i = 0; i < PERCENTUAL_SEVERIDADE.length; i++) {
      PERCENTUAL_SEVERIDADE_SOMA += PERCENTUAL_SEVERIDADE[i];
      PERCENTUAL_SEVERIDADE_RESULT.push(PERCENTUAL_SEVERIDADE_SOMA);
    }

    setDataSeveridadeDefeitos((prev: any) => ({
      ...prev,
      labels: UNIDADE,
      datasets: [
        {
          type: 'bar',
          yAxisID: "y-axis-1",
          label: 'Valor',
          borderColor: 'rgba(163, 142, 23, 0.8)',
          backgroundColor: 'rgba(163, 142, 23, 0.8)',
          fill: true,
          data: QUANTIDADE_DEFEITOS,
        },
        {

          type: 'line',
          label: 'Percentual Acumulado',
          // yAxisID: "y-axis-2",
          backgroundColor: 'gray',
          borderColor: 'gray',
          borderWidth: 2,
          fill: false,
          data: PERCENTUAL_SEVERIDADE_RESULT,
        },
      ],
    }));

    const UNIDADE_ORIGIN = defectsOrigin?.map(
      (item: any) => item.id_fornecedor_unidade
    );

    const QUANTIDADE_DEFEITOS_ORIGIN = defectsOrigin?.map((item: any) =>
      parseInt(item.qtd_defeitos)
    );

    const PERCENTUAL_SEVERIDADE_ORIGIN = defectsOrigin?.map((item: any) =>
      parseInt(item.percentual)
    );

    const PERCENTUAL_SEVERIDADE_ORIGIN_RESULT: number[] = []

    let PERCENTUAL_SEVERIDADE_ORIGIN_SOMA = 0;

    for (let i = 0; i < PERCENTUAL_SEVERIDADE_ORIGIN.length; i++) {
      PERCENTUAL_SEVERIDADE_ORIGIN_SOMA += PERCENTUAL_SEVERIDADE_ORIGIN[i];
      PERCENTUAL_SEVERIDADE_ORIGIN_RESULT.push(PERCENTUAL_SEVERIDADE_ORIGIN_SOMA);
    }

    setDataOriginDefects((prev: any) => ({
      ...prev,
      labels: UNIDADE_ORIGIN,
      datasets: [
        {
          type: 'bar',
          yAxisID: "y-axis-1",
          label: 'Valor',
          borderColor: 'rgba(163, 142, 23, 0.8)',
          backgroundColor: 'rgba(163, 142, 23, 0.8)',
          fill: true,
          data: QUANTIDADE_DEFEITOS_ORIGIN,
        },
        {
          type: 'line',
          label: 'Percentual Acumulado',
          // yAxisID: "y-axis-2",
          backgroundColor: 'gray',
          borderColor: 'gray',
          borderWidth: 2,
          fill: false,
          data: PERCENTUAL_SEVERIDADE_ORIGIN_RESULT,  
        },
      ],
    }));
  }, [
    principaisDefeitos,
    principaisDefeitosOrigens,
    defeitosSeveridade,
    defectsOrigin,
    severidade,
    originDefect,
  ]);

  const todayDate = new Date();

  const today = todayDate.toLocaleDateString();

  return (
    <div style={{ width: "100%" }}>
      <div className="header">
        <h1>Estratificação de defeitos</h1>
      </div>
      <div className="info-extract">
        <div style={{ display: "flex", width: "100%" }}>
          {/* ANO */}
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <label style={{ marginRight: 15, color: "#555555", width: "100%" }}>Ano</label>
            <select
              id="statusSelect"
              name=""
              className="select-defect"
              style={{ outline: "none" }}
              onChange={(e) => {
                if (e.target.value == '0') {
                  setDisabledButton(true)
                }
                else {
                  setYear(e.target.value)
                }
              }}
            >
               <option value="0">Selecione...</option>
              {anosPesquisa.map((item: any) => {
                return (
                  <option value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          {/* FORNECEDOR */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <label style={{ marginRight: 15, color: "#555555", width: "100%" }}>
              Fornecedor
            </label>
            <select
              id="statusSelect"
              name=""
              className="select-defect"
              style={{ outline: "none" }}
              onChange={(e) => {
                if (e.target.value === "0") {
                  setDisabledButton(true);
                } else {
                  setObjectForn({ ...objectForn, idForn: e.target.value });
                  setDisabledButton(false);
                }
                setFornItem(e.target.value);
              }}
            >
              <option value="0">Selecione...</option>
            
              <option value="todos">Todos</option>
            
              {forn
                .sort((a: any, b: any) =>
                  a.fornecedorDescricao.localeCompare(b.fornecedorDescricao)
                )
                .map((item: any) => (
                  <option value={item.idFornecedor} key={item.idFornecedor}>
                    {item.fornecedorDescricao}
                  </option>
                ))}
            </select>
          </div>
          {/* PLANTA */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <label style={{ marginRight: 15, color: "#555555", width: "100%" }}>Planta</label>
            <select
              id="statusSelect"
              name=""
              className="select-defect"
              style={{ outline: "none" }}
              onChange={(e) => {
                if (e.target.value === "0") {
                  setDisabledButton(true);
                } else {
                  setDisabledButton(false);
                }
                setObjectForn({ ...objectForn, idUnidade: e.target.value });
              }}
            >
              <option value="0">Selecione...</option>
            
              <option value="todos">Todos</option>
            
              {FilterItemByName &&
                FilterItemByName.map((item: any) => (
                  <option key={item.idFornecedorUnidade} value={item.idFornecedorUnidade}>
                    {item.fornecedorUnidadeDescricao}
                  </option>
                ))}
            </select>
          </div>
        </div>
        {/* PESQUISAR */}
        <div style={{ display: "flex" }}>
          <button className="button-defect-search " onClick={() => Search()} disabled={disabledButton}>
            <BsSearch style={{ marginRight: 10 }} />
            Pesquisar
          </button>
        </div>
      </div>
      {showGrafic && (
        <div
          style={{
            display: "flex",
            borderTop: "1px solid rgba(34,36,38,.15)",
            marginTop: 12,
          }}
        >

          <div className="content-left">
            {/* Pareto de Principais Defeitos */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1
                style={{ fontSize: 14, marginBottom: 15 }}
                className="title-graphic"
              >
                <GoGraph
                  color="rgba(163, 142, 23, 0.8)"
                  size={20}
                  style={{ marginRight: 20 }}
                />
                Pareto de Principais Defeitos
              </h1>
              <BarChat data={userData} options={options} />
            </div>

            {/* Pareto de Fornecedores por Severidade de Defeito */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1 style={{ fontSize: 14 }} className="title-graphic">
                <GoGraph
                  color="rgba(163, 142, 23, 0.8)"
                  size={20}
                  style={{ marginRight: 20 }}
                />
                Pareto de Fornecedores por Severidade de Defeito
              </h1>
              <div className="filter-graphic">
                <div style={{ width: "100%" }}>
                  <p style={{ fontSize: 14, margin: 0 }}>
                    Severidade do defeito :
                  </p>
                </div>
                <div className="content-filter-graph">
                  <div className="div-input-filter-graph">
                    <input
                      type="radio"
                      onChange={(e) => {
                        setSeveridade(e.target.value);
                        filterSeveridade(e.target.value);
                      }}
                      checked={severidade === "Crítico" ? true : false}
                      value="Crítico"
                    />
                    <label>Crítico</label>
                  </div>
                  <div className="div-input-filter-graph">
                    <input
                      type="radio"
                      onChange={(e) => {
                        setSeveridade(e.target.value);
                        filterSeveridade(e.target.value);
                      }}
                      checked={severidade === "Grave" ? true : false}
                      value="Grave"
                    />
                    <label>Grave</label>
                  </div>
                  <div className="div-input-filter-graph">
                    <input
                      type="radio"
                      onChange={(e) => {
                        setSeveridade(e.target.value);
                        filterSeveridade(e.target.value);
                      }}
                      checked={severidade === "Tolerável" ? true : false}
                      value="Tolerável"
                    />
                    <label>Tolerável</label>
                  </div>
                </div>
              </div>
              <BarChat data={dataSeveridadeDefeitos} options={options} />
            </div>
          </div>

          <div className="content-right">
            {/* Pareto de Principais Origens de Defeitos */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1
                style={{ fontSize: 14, marginBottom: 15 }}
                className="title-graphic"
              >
                <GoGraph
                  color="rgba(163, 142, 23, 0.8)"
                  size={20}
                  style={{ marginRight: 20 }}
                />
                Pareto de Principais Origens de Defeitos
              </h1>

              <BarChat data={defeitosOrigem} options={options} />
            </div>

            {/* Pareto de Fornecedores por Origem de Defeito */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1 style={{ fontSize: 14 }} className="title-graphic">
                <GoGraph
                  color="rgba(163, 142, 23, 0.8)"
                  size={20}
                  style={{ marginRight: 20 }}
                />
                Pareto de Fornecedores por Origem de Defeito
              </h1>
              <div className="filter-graphic">

                <div style={{ width: "100%" }}>
                  <p style={{ fontSize: 14, margin: 0 }}>Origem do defeito</p>
                </div>
                
                <div className="content-filter-graph">
                  <div
                    className="div-input-filter-graph"
                    style={{ marginRight: 35 }}
                  >
                    <input
                      type="radio"
                      onChange={(e) => {
                        setOriginDefect(e.target.value);
                        filterOrigem(e.target.value);
                      }}
                      checked={originDefect === "Recebimento" ? true : false}
                      value="Recebimento"
                    />
                    <label>Recebimento</label>
                  </div>
                  <div className="div-input-filter-graph">
                    <input
                      type="radio"
                      onChange={(e) => {
                        setOriginDefect(e.target.value);
                        filterOrigem(e.target.value);
                      }}
                      checked={originDefect === "Processo" ? true : false}
                      value="Processo"
                    />
                    <label>Processo</label>
                  </div>
                  <div className="div-input-filter-graph">
                    <input
                      type="radio"
                      onChange={(e) => {
                        setOriginDefect(e.target.value);
                        filterOrigem(e.target.value);
                      }}
                      checked={originDefect === "Estoque" ? true : false}
                      value="Estoque"
                    />
                    <label>Estoque</label>
                  </div>
                </div>
              </div>
              <BarChat data={dataOriginDefects} options={options} />
            </div>

          </div>
        </div>
      )}
      {showGrafic && (
        <div>
          <h2
            style={{
              fontSize: 14,
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
            }}
          >
            {" "}
            <AiFillInfoCircle
              color="rgba(163, 142, 23, 0.8)"
              size={20}
              style={{ marginRight: 6 }}
            />{" "}
            Última atualização: {today}
          </h2>
        </div>
      )}
    </div>
  );
};

export default ErrorsNce;
